.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.highlight {
  background-color: rgba(128, 128, 128, 0.2);
  cursor: pointer;
}


::-webkit-scrollbar {
  border-radius: 6px;
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: #101D42;
  border-radius: 0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #D9D9D9;
  transition: background-color 0.3s ease-in-out;
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #b3b3b3;
}

div {
  ::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 0;

    box-shadow: inset 0 0 0 rgb(0 0 0 / 50%);
  }
}